<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-row>
      <b-col cols="12">
        <web-heading />
      </b-col>
    </b-row>
    <div class="w-100 mb-1 text-center">
      <p
        class="mb-1"
        v-html="$t('DOWNLOAD_YOUR_APP_HERE')"
      />
      <b-link
        target="_blank"
        :href="appUrl.android"
      >
        <b-img
          fluid
          :src="require('@/assets/images/pages/google_badge.svg')"
          width="150px"
          height="60px"
          class="center-img"
          alt="landing page"
        />
      </b-link>

      <b-link
        target="_blank"
        :href="appUrl.ios"
      >
        <b-img
          fluid
          :src="require('@/assets/images/pages/ios_badge.svg')"
          width="136px"
          height="60px"
          alt="landing page"
        />
      </b-link>

    </div>
    <b-card class="mb-0">

      <b-card-text class="mb-2 text-center">
        <h2>{{ $t('CONGRATULATIONS') }} ! 🎉 🎯</h2>
      </b-card-text>
      <b-card-text class="mb-2 text-center">
        {{ welcomeText }}
      </b-card-text>
      <b-card-text class="mb-2">
        <div class="w-100 text-center">
          <b-img
            fluid
            :src="require('@/assets/images/pages/Award-img-1.png')"
            alt="logo img"
            width="150px"
            height="60px"
          />
        </div>
      </b-card-text>
      <b-card-text class="mb-2 text-center">
        The bisevo Team
      </b-card-text>
    </b-card>

  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BImg, BLink,
} from 'bootstrap-vue'

import WebHeading from '../utility/WebHeading.vue'
import constants from '@/constants/static.json'
import router from '@/router'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BImg,
    BLink,
    WebHeading,
  },
  data() {
    return {
      welcomeText: '',
      appUrl: {
        android: constants.APP_URL.ANDROID,
        ios: constants.APP_URL.IOS,
        webGl: constants.APP_URL.WEB_GL,
      },
    }
  },
  mounted() {
    if (router.currentRoute.query.id === constants.LOCALE.ENGLISH) {
      this.$i18n.locale = constants.LOCALE.ENGLISH
    }
    this.welcomeText = this.$i18n.t('WELCOME_ADMIN_TEXT')
    if (router.currentRoute.params.id) {
      this.welcomeText = this.$i18n.t('WELCOME_USER_TEXT')
    }
  },
  created() {
    this.$i18n.locale = constants.LOCALE.SWEDISH
  },
}
</script>
